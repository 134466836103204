<template>
  <h2>經紀費管理</h2>

  <div class="my-4 text-end">
    <router-link :to="`/admin/income`">返回列表頁</router-link>
  </div>

  <div class="card">
    <h5 class="card-header">新增經紀費</h5>
    <div class="card-body">
      <form @submit.prevent="set()">
        <div class="mb-3">
          <label class="form-label">月 / 年</label>
          <month-picker-input
            :lang="`ja`"
            :default-month="date.month"
            :default-year="date.year"
            :input-pre-filled="true"
            @change="showDate"
          ></month-picker-input>
        </div>
        <hr class="mb-5" />

        <div class="text-end mb-5">
          <div>
            <i class="bi bi-exclamation-triangle-fill"></i> 一次要增加的組數
            <input
              class="mb-3"
              type="number"
              size="1"
              v-model="itemNum"
              min="1"
              max="100"
            />
          </div>
          <button type="button" class="btn btn-primary" @click="addItem()">
            多新增 {{ itemNum }} 組
          </button>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div
              v-for="(d, index) in data"
              :key="index"
              class="row mb-4 align-items-center"
            >
              <div class="col-md-1 text-center">
                <div class="h4 mt-4">{{ index + 1 }}</div>
              </div>
              <div class="col-md-2">
                <label class="form-label">會員</label>
                <Multiselect
                  v-model="d.memberId"
                  mode="single"
                  :searchable="true"
                  :required="true"
                  :options="memberOption"
                />
              </div>
              <div class="col-md-2">
                <label class="form-label">經紀費金額</label>
                <input
                  type="number"
                  class="form-control"
                  v-model.number="d.brokerageFees"
                  required
                  min="1"
                />
              </div>
              <div class="col-md-2">
                <label class="form-label">上台數</label>
                <input
                  type="number"
                  class="form-control"
                  v-model.number="d.hoursOnStage"
                  required
                  min="0.01"
                  step="0.01"
                />
              </div>
              <div class="col-md-2">
                <label class="form-label">上班天數</label>
                <input
                  type="number"
                  class="form-control"
                  v-model.number="d.daysAtWork"
                  required
                  min="1"
                />
              </div>
              <div class="col-md-2">
                <label class="form-label">薪資金額</label>
                <input
                  type="number"
                  class="form-control"
                  v-model.number="d.salary"
                  required
                  min="1"
                />
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  class="btn btn-danger"
                  v-if="index > 0"
                  @click="removeItem(index)"
                >
                  <i class="bi bi-x-circle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center my-5">
          <button type="submit" class="btn btn-success btn-lg">儲存</button>
          <!-- <div class="mt-4 h5">
						<i class="bi bi-info-circle-fill"></i>
						必須 <u>會員</u> 與 <u>經濟費金額</u> 皆有設定的那筆紀錄才會儲存
					</div> -->
        </div>
      </form>
    </div>
  </div>

  <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
</template>

<script>
import { MonthPickerInput } from "vue-month-picker";
import Multiselect from "@vueform/multiselect";
// import { memberService } from "../../../services/memberService.js";
import { brokerageFeesService } from "../../../services/brokerageFeesService.js";
import { variableService } from "../../../services/variableService.js";
export default {
  components: {
    MonthPickerInput,
    Multiselect,
  },
  data() {
    return {
      itemNum: 1,
      memberOption: [],
      data: [
        {
          memberId: null,
          brokerageFees: null,
          hoursOnStage: null,
          daysAtWork: null,
          salary: null,
        },
      ],
      selectedDate: {
        month: null,
        year: null,
      },
      date: {
        from: null,
        to: null,
        month: null,
        year: null,
      },
    };
  },
  methods: {
    showDate(date) {
      this.selectedDate.month = date.monthIndex;
      this.selectedDate.year = date.year;
    },
    addItem() {
      if (this.itemNum >= 200) {
        alert("為了避免畫面渲染過久, 一次只能新增200筆");
        this.itemNum = 200;
      }

      for (let i = 1; i <= this.itemNum; i++) {
        this.data.push({
          memberId: null,
          brokerageFees: null,
          hoursOnStage: null,
          daysAtWork: null,
          salary: null,
        });
      }
    },
    removeItem(index) {
      this.data.splice(index, 1);
    },
    hasDuplicate(arrayObj, colName) {
      const hash = Object.create(null);
      return arrayObj.some((arr) => {
        return (
          arr[colName] && (hash[arr[colName]] || !(hash[arr[colName]] = true))
        );
      });
    },
    async set() {
      const isDuplicate = this.hasDuplicate(this.data, "memberId");

      if (isDuplicate) {
        alert("您重複設定了會員, 請將同一位會員設定同一筆經濟費金額");
      } else {
        await brokerageFeesService.set(this.data, this.selectedDate);
        alert("經濟費儲存成功, 待審查中");
        this.$router.push({ name: "income" });
      }
    },
  },
  async created() {
    const now = new Date();
    this.date.month = this.selectedDate.month = now.getMonth() + 1;
    this.date.year = this.selectedDate.year = now.getFullYear();

    // 取得會員名單
    // this.memberOption = await memberService.getMembersSelectOptions_withOutOne(
    //   false
    // );

    this.memberOption = await variableService.get_allMember_selectOption(true, [
      "name",
      "member_id",
      "store",
      "numberStageName",
    ]);

    // console.log(this.memberOption);
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.month-picker__container {
  z-index: 1;
}
</style>
